import Pristine from 'pristinejs';

const serialize = function (formEle) {
  // Get all fields
  const fields = [].slice.call(formEle.elements, 0);

  return fields
    .map(function (ele) {
      const name = ele.name;
      const type = ele.type;

      // We ignore
      // - field that doesn't have a name
      // - disabled field
      // - `file` input
      // - unselected checkbox/radio
      if (
        !name ||
        ele.disabled ||
        type === 'file' ||
        (/(checkbox|radio)/.test(type) && !ele.checked)
      ) {
        return '';
      }

      // Multiple select
      if (type === 'select-multiple') {
        return ele.options
          .map(function (opt) {
            return opt.selected
              ? `${encodeURIComponent(name)}=${encodeURIComponent(opt.value)}`
              : '';
          })
          .filter(function (item) {
            return item;
          })
          .join('&');
      }

      return `${encodeURIComponent(name)}=${encodeURIComponent(ele.value)}`;
    })
    .filter(function (item) {
      return item;
    })
    .join('&');
};

const submitForm = (formEle) => {
  return new Promise(function (resolve, reject) {
    // Serialize form data
    // See https://htmldom.dev/serialize-form-data-into-a-query-string
    const params = serialize(formEle);

    // Create new Ajax request
    const req = new XMLHttpRequest();
    req.open('POST', formEle.action, true);
    req.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8',
    );

    // Handle the events
    req.onload = function () {
      if (req.status >= 200 && req.status < 400) {
        resolve(req.responseText);
      }
    };
    req.onerror = function () {
      reject();
    };

    // Send it
    req.send(params);
  });
};

const formValidation = () => {
  const forms = document.querySelectorAll('.c-form');
  if (forms) {
    forms.forEach((form) => {
      const messageBox = form.querySelector('.c-form__response');
      const pristine = new Pristine(form, {
        classTo: 'c-input',
        errorTextParent: 'c-input',
        errorClass: 'c-input--error',
        successClass: 'c-input--success',
      });

      form.addEventListener('submit', function (e) {
        e.preventDefault();

        messageBox.style.display = 'none';

        if (!pristine.validate()) {
          return false;
        }

        submitForm(form).then(function (response) {
          const data = JSON.parse(response);

          if (data.success) {
            messageBox.classList.remove('c-form__response--error');
            messageBox.classList.add('c-form__response--success');
            messageBox.innerHTML = data.message;

            messageBox.style.display = '';

            e.target.reset();
            pristine.reset();
          } else {
            messageBox.classList.remove('c-form__response--success');
            messageBox.classList.add('c-form__response--error');
            messageBox.innerHTML = data.message;

            messageBox.style.display = '';
          }
          console.log(data);
        });
      });
    });
  }
};

formValidation();
