import fullpage from 'fullpage.js';
import Swiper, { EffectFade } from 'swiper';
import Freezeframe from 'freezeframe';

const scrollFeature = () => {
  const bar = document.querySelector('.c-progress__bar');
  const nextBtn = document.querySelector('[data-next-slide]');
  const prevBtn = document.querySelector('[data-prev-slide]');
  const sections = document.querySelectorAll('.c-section');

  const pageable = new fullpage('#sections', {
    licenseKey: 'K80AH-5MWY7-G05KK-8JI07-WMNQO',
    sectionSelector: '.c-section',
    afterLoad: function (origin, destination, direction, trigger) {
      prevBtn.removeAttribute('disabled');
      nextBtn.removeAttribute('disabled');

      const pos =
        1 - (sections.length - 1 - destination.index) / (sections.length - 1);
      bar.style.transform = `scale(${pos},1)`;

      if (destination.isFirst) {
        prevBtn.setAttribute('disabled', 'disabled');
      }
      if (destination.isLast) {
        nextBtn.setAttribute('disabled', 'disabled');
      }
    },
  });

  fullpage_api.setAllowScrolling(false);

  // # devices
  const $Speed = 1000;

  const deviceTextSwiper = new Swiper('.c-slider--text', {
    mousewheel: false,
    speed: $Speed,
    loop: false,
    longSwipesRatio: 0.01,
    followFinger: false,
    allowTouchMove: false,
    grabCursor: false,
    watchSlidesProgress: true,
    direction: 'vertical',
    effect: 'slide',
    parallax: true,
    lazy: {
      loadPrevNext: true,
    }
  });


  let deviceImageSwiper = new Swiper('.c-slider--image', {
    mousewheel: false,
    speed: $Speed,
    loop: false,
    longSwipesRatio: 0.01,
    followFinger: false,
    allowTouchMove: false,
    grabCursor: false,
    watchSlidesProgress: true,
    direction: 'vertical',
    effect: 'slide',
    parallax: true,
    lazy: {
      loadPrevNext: true,
    }
  });

  let nextfullJS = false;
  let prevfullJS = true;
  deviceImageSwiper.on('slideChange', function () {
    if (deviceImageSwiper.isBeginning) {
      prevfullJS = true;
    } else {
      prevfullJS = false;
    }

    if (deviceImageSwiper.isEnd) {
      nextfullJS = true;
    } else {
      nextfullJS = false;
    }
  });

  const ff = new Freezeframe({
    trigger: false
  });

  prevBtn.addEventListener('click', () => {
    const section = fullpage_api.getActiveSection();
    ff.start();

    if (section.item.dataset.horizontal !== 'true') {
      fullpage_api.moveSectionUp();
    } else {
      if (prevfullJS) {
        fullpage_api.moveSectionUp();
      } else {
        deviceTextSwiper.slidePrev();
        deviceImageSwiper.slidePrev();
      }
    }
  });

  nextBtn.addEventListener('click', () => {
    const section = fullpage_api.getActiveSection();
    ff.start();

    if (section.item.dataset.horizontal !== 'true') {
      fullpage_api.moveSectionDown();
    } else {
      if (nextfullJS) {
        fullpage_api.moveSectionDown();
      } else {
        deviceTextSwiper.slideNext();
        deviceImageSwiper.slideNext();
      }
    }
  });
};
document.addEventListener('touchmove', function (event) {
  if (event.scale !== 1) { event.preventDefault(); }
}, { passive: false });

window.addEventListener('DOMContentLoaded', scrollFeature, { once: true });
