import lozad from 'lozad';

const lazy = () => {
  const lazyEl = document.querySelector('.u-lazy');

  if (lazyEl) {
    const observer = lozad('.u-lazy', {
      rootMargin: '10px 0px',
      threshold: 0.1,
    });
    const observe = () => observer.observe();
    observe();

    document.addEventListener('DOMContentLoaded', () => observe, {
      once: true,
    });
  }
};

lazy();
