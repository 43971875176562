import { CountUp } from 'countup.js';
import inView from 'in-view';

const itemsToCount = document.querySelectorAll('.u-countup');

if (itemsToCount) {
  itemsToCount.forEach((value) => {
    const valueNum = Number(value.innerText);
    const valueNumLen = Math.ceil(Math.log10(valueNum + 1));
    const suffix = value.dataset.counterSuffix;

    const countValue = new CountUp(value, valueNum, {
      duration: 3,
      decimal: ',',
      separator: '.',
      decimalPlaces: valueNum % 1 !== 0 ? 2 : 0,
      suffix: suffix,
    });

    inView('.u-countup').on('enter', (element) => {
      countValue.start();
    });
  });
}
